import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../../../components/seo';
import PrimaryLayout from '../../../components/layout/primary/primary';
import {  AmplifySignOut } from '@aws-amplify/ui-react';
import AuthStateApp from '../../../components/Authenticator/AuthStateApp'
import Orders from '../../../components/profile/orders';


const OrdersPage: React.FC<{}> = () => (
	
	<PrimaryLayout>
		<SEO title="Chat2order shop Orders" />
		<Container py="40px">
		<AuthStateApp>
			< Orders />
		</AuthStateApp>	
		</Container>
	</PrimaryLayout>
	
);

export default OrdersPage;

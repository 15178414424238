/** @jsx jsx */
import { useRef, useState, useEffect, Fragment } from 'react';
import { Grid, Box, Flex, Heading, Button, jsx } from 'theme-ui';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import Logo from '../../../../logo/logo';
import Container from '../../../../container/container';
import MainMenu from '../navbar/main-menu';
import Footer from '../../footer/footer';
import useOnClickOutside from '../../../../../hooks/useOutsideClick';
import useWindowSize from '../../../../../hooks/useWindowSize';
import styles from './topbar.style';
import { Link } from 'gatsby';

const Topbar: React.FC<{ fluid?: boolean; homeLink?: string }> = ({
	fluid,
	homeLink,
}) => {
	const windowSize = useWindowSize();
	const [visible, setVisible] = useState(false);
	const [mobileSearch, setMobileSearch] = useState(false);
	const mobileSearchRef = useRef(null!);
	useOnClickOutside(mobileSearchRef, () => setMobileSearch(false));
	useEffect(() => {
		if (windowSize && windowSize > 1080) {
			setVisible(false);
		}
	}, [windowSize]);

	return (
		<Fragment>
			<Box sx={styles.topbar}>
				<Container fluid={fluid}>
					{(
						<Grid className="grid" sx={styles.grid}>
							<Flex className="logo-area">
								<Logo path={homeLink} />
							</Flex>

							<Box className="topbar-links" sx={styles.rightPart}>
								<Link
									to={'/profile'}
								>
									<svg width="10.667" height="16" viewBox="0 0 10.667 16">
										<g transform="translate(-6 -3)">
											<path
												d="M15.111,6.556A3.556,3.556,0,1,1,11.556,3,3.556,3.556,0,0,1,15.111,6.556Zm-1.778,0a1.778,1.778,0,1,1-1.778-1.778A1.778,1.778,0,0,1,13.333,6.556Z"
												transform="translate(-0.222)"
												fill="#292929"
												fillRule="evenodd"
											/>
											<path
												d="M14.889,14.667A.889.889,0,0,0,14,13.778H8.667a.889.889,0,0,0-.889.889V20H6V14.667A2.667,2.667,0,0,1,8.667,12H14a2.667,2.667,0,0,1,2.667,2.667V20H14.889Z"
												transform="translate(0 -1)"
												fill="#292929"
											/>
										</g>
									</svg>
								</Link>
							</Box>
						</Grid>
					)}
				</Container>
			</Box>
		</Fragment>
	);
};

export default Topbar;

export const GetUserOrders = `
  query GetUserOrders($user:String!) {
    getUserOrder(user:$user) {    
    cartTotal
    createdAt
    payment
    shipping
    shopRef
    status
    total
    statusUrl
   }
  }
`

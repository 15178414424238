import React from 'react';
import {Amplify, Auth, Hub} from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Alert, Button, Box, Input, Label, Link, Container} from 'theme-ui';
import { navigate } from "gatsby"
import styles from './auth.style';
import PrimaryLayout from '../layout/primary/primary';

Amplify.configure(awsconfig);

const AuthStateApp = ({children, onClick={}, showGuest=false}) => {
const [formInputState, setFormInputState]  = React.useState({ username: '', password: '', email: '', verificationCode: '' });

const isBrowser = typeof window !== "undefined"

/* onChange handler for form inputs */
	function onChange(e) {
	  setFormInputState ({ ...formInputState, [e.target.name]: e.target.value });
	}
	async function signUp() {
	  try {
		await Auth.signUp({
		  username: '+91' + formInputState.username,
		  password: Date.now().toString(),
		});
		/* Once the user successfully signs up, update form state to show the confirm sign up form for MFA */
		setAuthState( { ...authState, state: "signIn", error: '' });
	  } catch (err) { 
	    console.log({ err }); 
	    setAuthState({ ...authState, error: err.message});
	  }
	}  

	async function signIn() {
	  try {
		const cognitoUser = await Auth.signIn('+91' + formInputState.username, formInputState.password);
		/* Once the user successfully signs in, update the form state to show the signed in state */
		setAuthState({ user: cognitoUser, state: "otpInput", error: '' });
	  } catch (err) { 
	    console.log({ err }); 
	    if (err.message.includes('User does not exist, Please signup.'))
	    {
	      err.message = 'User does not exist, Please signup.'
	      setAuthState({ ...authState, state: "signUp", error: err.message});
	    }
	    else {
	      setAuthState({ ...authState, error: err.message});	    
	    }
	  }
	}	

	async function confirmOtp() {
	  try {
		const cognitoUser = await Auth.sendCustomChallengeAnswer(authState.user, formInputState.otp);
		/* Once the user successfully signs in, update the form state to show the signed in state */
		console.log(`user: ${JSON.stringify(cognitoUser)}`)
		if(cognitoUser.signInUserSession) {
		  setAuthState({ user: cognitoUser, state: "signedIn", error: ''  });
		}
		else {
		  setAuthState({ ...authState, error: "Incorrect OTP - try again"});
		}
		
	  } catch (err) { 
	    console.log({ err }); 
	    if(err.code === "UserLambdaValidationException" && err.message.includes("Invalid OTP")) {
	      setAuthState({ ...authState, state: "signIn", error: "wrong OTP - exceeded max. 3 attempts"});
	    }
	    else {
	      setAuthState({ ...authState, error: err.message});	    
	    }
	  }
	}
	 
    const [authState, setAuthState] = React.useState({ state: 'signIn', user: {}, error: ''});

	const childrenWithProps = React.Children.map(children, child => {
		// checking isValidElement is the safe way and avoids a typescript error too
		//console.log("usererror in prop: ", JSON.stringify(userError))
		const props = { user: '' };
		if (React.isValidElement(child)) {
			return React.cloneElement(child, props);
		}
		return child;
	});

    React.useEffect(() => {
		(async function onAppLoad() {
		  const user = await Auth.currentAuthenticatedUser();
		  console.log('user:', user)
		  if (user) {
			setAuthState({ ...authState, state: "signedIn", error: ''  });
		  } else {
		    setAuthState({ ...authState, state: "signIn", error: ''  });
		  }
		}) ();

		Hub.listen('auth', (data) => {
		  const event = data.payload.event;
		  console.log('event:', event);
		  if (event === "signOut") {
			console.log('user signed out...');
			setAuthState( { ...authState, state: "signIn", error: ''  });
		  }
		});		
    }, []);

    
  
    

  return  authState.state === "signedIn"  ? (
      <>
		{childrenWithProps}
      </>
    ) : isBrowser && window?.location.href.includes('/profile') ? 
	(
     <Container py="10px">     
    { authState.state === "signUp" && 
    (<Box>
  	  <Label htmlFor="username" mb={3} >10 digit mobile no.</Label>      
      <Input
        mb={3}
        onChange={onChange}
        name="username"
        placeholder="9876543210"
      />
      <Button onClick={signUp} mb={3}>Sign Up</Button>
      <Link href="#" onClick={(e) => { e.preventDefault(); setAuthState({ ...authState, state: "signIn", error: '' })} } mb={3}>Already registered? sign in</Link>      
      {authState.error ? <Alert>{authState.error}</Alert> : ''}
    </Box>
    ) 
    }
    { authState.state === "otpInput" && 
    (     <Box>
      <Label htmlFor="username" mb={3} >mobile no.</Label>
      <Input
        mb={3}
        name="otp"
        value={formInputState.username}
        disabled
      />  	  
  	  <Label htmlFor="otp" mb={3} >4 digit OTP:</Label>      
      <Input
        mb={3}
        onChange={onChange}
        name="otp"
        placeholder="1234"
        autocomplete="one-time-code"
      />
      <Button onClick={confirmOtp} mb={3}>submit</Button>      
      {authState.error ? <Alert>{authState.error}</Alert> : ''}
    </Box>
    ) 
    }  
    { authState.state === "signIn" && 
    (<Box>
  	  <Label htmlFor="username" mb={3} >10 digit mobile no.</Label>      
      <Input
        mb={3}
        onChange={onChange}
        name="username"
        placeholder="9876543210"
      />
      <Button onClick={signIn} mb={3}>Send OTP</Button>
      <Link href="#" onClick={(e) => { e.preventDefault(); setAuthState({ ...authState, state: "signUp", error: '' })} } mb={3}>OR sign up</Link>
      {authState.error ? <Alert>{authState.error}</Alert> : ''}    
    </Box> 
    ) 
    }      
    </Container>    
    )    
    : (
     <PrimaryLayout>
     <Container py="10px">     
    { authState.state === "signUp" && 
    (<Box>
  	  <Label htmlFor="username" mb={3} >10 digit mobile no.</Label>      
	  {authState.error && authState.error === 'User does not exist, Please signup.' ? 
		( <Input
         mb={3}
         onChange={onChange}
         name="username"
         value={formInputState.username}
         /> )	  
	  :( <Input
         mb={3}
         onChange={onChange}
         name="username"
         placeholder="9876543210"
         /> )}
      <Button onClick={signUp} mb={3}>Sign Up</Button>
      <Link href="#" onClick={(e) => { e.preventDefault(); setAuthState({ ...authState, state: "signIn", error: '' })} } mb={3}>Already registered? sign in</Link>      
      {authState.error ? <Alert>{authState.error}</Alert> : ''}
    </Box>
    ) 
    }
    { authState.state === "otpInput" && 
    (     <Box>
      <Label htmlFor="username" mb={3} >mobile no.</Label>
      <Input
        mb={3}
        name="otp"
        value={formInputState.username}
        disabled
      />  	  
  	  <Label htmlFor="otp" mb={3} >4 digit OTP:</Label>      
      <Input
        mb={3}
        onChange={onChange}
        name="otp"
        placeholder="1234"
        autocomplete="one-time-code"
      />
      <Button onClick={confirmOtp} mb={3}>submit</Button>      
      {authState.error ? <Alert>{authState.error}</Alert> : ''}
    </Box>
    ) 
    }  
    { authState.state === "signIn" && 
    (<Box>
  	  <Label htmlFor="username" mb={3} >10 digit mobile no.</Label>      
      { formInputState.username ? 
		(<Input
        mb={3}
        onChange={onChange}
        name="username"
        value={formInputState.username}
        /> )     
      : (<Input
        mb={3}
        onChange={onChange}
        name="username"
        placeholder="9876543210"
      />)
      }
      <Button onClick={signIn} mb={3}>Send OTP</Button>
      <Link href="#" onClick={(e) => { e.preventDefault(); setAuthState({ ...authState, state: "signUp", error: '' })} } mb={3}>OR sign up</Link>
      {authState.error ? <Alert>{authState.error}</Alert> : ''}    
    </Box> 
    ) 
    }      
    </Container>
    </PrimaryLayout>    
    )
}

export default AuthStateApp;
/** @jsx jsx */
import React from 'react';
import {  AmplifySignOut } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import awsconfig from '../../aws-exports';
import { Grid, Box, jsx } from 'theme-ui';
import { animated, useSpring, useChain } from 'react-spring';
import Order from './Order';
import styles from './profile.style';

Amplify.configure(awsconfig);

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

const Orders = ({user}) => {
	
	//const [open, set] = React.useState(false);
	const [display, set] = React.useState({open: false, id: ""});
	const cartOverlayRef = React.useRef();
	// @ts-ignore
	const { size, ...rest } = useSpring({
		ref: cartOverlayRef,
		config: { mass: 1, tension: 800, friction: 50 },
		from: {
			size: '70%',
			left: '15%',
			opacity: 0,
			bottom: '30px',
			borderRadius: 10,
		},
		to: {
			opacity: display.open ? 1 : 0,
			size: display.open ? '100%' : '70%',
			left: display.open ? '0' : '15%',
			bottom: display.open ? '0' : '30px',
			borderRadius: display.open ? 0 : 10,
		},
	});
	useChain(
		// @ts-ignore
		display.open ? [ cartOverlayRef] : [cartOverlayRef],
		[0, display.open ? 0.1 : 0.2]
	);
	
	const [orders, setOrders] = React.useState([]);
    React.useEffect(() => {
    
    async function fetchData() {
 	  const user = await Auth.currentAuthenticatedUser();
	 if (user) {
		 
		const ordersData = await API.graphql({
		  query: queries.GetUserOrders,
		  variables: { user: user.username.substring(1) },
		  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
		});		 

			setOrders(ordersData.data.getUserOrder);
			console.log("orders: ", ordersData)
		}
		}
		fetchData();
    }, []);
	//console.log("user: ", user)
	return (
		<>
			<h2>Orders</h2>
			<Grid gap={'1px'} width={[ 64, null, 82 ]}>
			  <Box p={2} color='white' bg='primary'>Number</Box>
			  <Box p={2} color='white' bg='primary'>Date</Box>
			  <Box p={2} color='white' bg='primary'>Amount</Box>
			  <Box p={2} color='white' bg='primary'>Status</Box>
			</Grid>	
			{orders.length > 0 && 
			orders.map( (order, index) => (
				<Grid gap={'1px'} width={[ 64, null, 82 ]} key={order.shopRef} mb={'1px'} sx={styles.grid}>
				  <Box p={2} bg='muted'><a href="#" onClick={() => {}}>{order.shopRef}</a></Box>
				  <Box p={2} bg='muted'>{order.createdAt.split('T')[0]}</Box>
				  <Box p={2} bg='muted'>{order.total}</Box>
				  <Box p={2} bg='muted'>{order.statusUrl ? (<a href="#" onClick={(e) => {e.preventDefault(); window.open(order.statusUrl, "_blank")}}>click</a>) : order.status}</Box>				
				</Grid>
			))
			}
			<animated.div
				style={{
					...rest,
					width: size,
					height: size,
					position: 'fixed',
					backgroundColor: 'white',
					pointerEvents: display.open ? 'all' : 'none',
					zIndex: 9999,
				}}
			>
				<Order onClick={() => set({open: false, id: ""})} id={display.id}>
				</Order>
			</animated.div>			
		</>
	)
}


export default Orders;